
import { Component, Vue, } from 'vue-property-decorator'
import { mapState, mapGetters, } from 'vuex'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"
import { TemplateRecomendation, } from '@/store/types/Dataroom/Templates'
import { TemplateCrudRecomendation, } from '@/store/types/Dataroom/CrudTemplate'
import { DataroomTemplate, } from '@/store/types/Dataroom/DataroomTemplate'
import { FileUploadInterface, } from '@/store/modules/dataroom/dataroomModule'

import VueEditor from '@/components/VueEditor/Index.vue'
import ChipHackmetrix from '@/components/Chip/Index.vue'

@Component({
  computed: {
    ...mapState('RecomendationModule', ['RecomendationState',]),
    ...mapGetters('PolimorficModule', ['getFramework',]),
  },
  components: {
    ChipHackmetrix,
    VueEditor,OverlayBackground
  },
})
export default class DialogTemplatesComponent extends Vue {
  private loading = false;
  private templates = this.$store.state.RecomendationModule.RecomendationState.templates
  private templateSelected = {
    id: 0,
    title: '',
    description: '',
    tags: [],
    datarooms: [],
  }
  private title = ''
  private description = ''
  private tags = ['ISO 27001', 'ISO 270012',]
  private selectedTags: any[] = []
  private files = []
  private datarooms: string[] = []
  private dataroomsName: string[] = []
  private deleteRoom: any[] = []
  private currentDatarooms: DataroomTemplate[] = []
  private fileToUpload: FileUploadInterface[] = []

  mounted () {
    if (this.$route.name === 'templateEdit') {
      this.getTemplateById()
    } else {
      this.resetVars()
    }
  }

  private getTemplateById () {
    if (this.$route.name !== 'templateEdit') {
      return
    }

    this.$store
      .dispatch('RecomendationModule/getTemplatesbyId', Number(this.$route.params.id))
      .then((response) => {
        this.title = response.data.template.title
        this.templateSelected.id = response.data.template.id
        this.description = response.data.template.description
        this.tags = response.data.template.tags
        this.selectedTags = response.data.template.tags
        this.currentDatarooms = response.data.template.datarooms
      })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private selectTag (event: any, element: any) {
    if (this.selectedTags.indexOf(element.name) == -1) {
      this.selectedTags.push(element.name)
    } else {
      this.selectedTags = this.selectedTags.filter((tag) => tag != element.name)
    }
  }

  private assignedTitleEvidence (element: string) {
    this.title = element
  }

  private assignedContentEvidence (element: string) {
    this.description = element
  }

  private toDataroomEvidence (template: TemplateRecomendation) {
    this.currentDatarooms = template.datarooms
  }

  // @TODO: merge deleteFileAPi deleteFileLocal methods
  private deleteFileAPi (index: number) {
    this.deleteRoom.push(this.currentDatarooms[index].id)
    this.currentDatarooms.splice(index, 1)
  }

  private deleteFileLocal (index: number) {
    this.dataroomsName.splice(index, 1)
    this.fileToUpload.splice(index, 1)
  }

  private handleFiles () {
    if (this.files === []) return

    const refFiles: any = this.$refs.myFiles
    const reader = new FileReader()
    reader.readAsDataURL(refFiles.files[0])
    reader.onload = () => {
      const fileAux: string = refFiles.files[0].name.toLowerCase()
      if (
        fileAux.split('.').pop() === 'txt' ||
        fileAux.split('.').pop() === 'pdf' ||
        fileAux.split('.').pop() === 'doc' ||
        fileAux.split('.').pop() === 'docx' ||
        fileAux.split('.').pop() === 'xls' ||
        fileAux.split('.').pop() === 'xlsx' ||
        fileAux.split('.').pop() === 'ppt' ||
        fileAux.split('.').pop() === 'pptx' ||
        fileAux.split('.').pop() === 'jpg' ||
        fileAux.split('.').pop() === 'jpeg' ||
        fileAux.split('.').pop() === 'png' ||
        fileAux.split('.').pop() === 'gif'
      ) {
        this.dataroomsName.push(fileAux)
        const readerResult: string | undefined = reader?.result?.toString()
        if (readerResult) this.datarooms.push(readerResult)

        this.fileToUpload.push({
          filename: fileAux,
          data: readerResult,
        })
      } else {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Error, Tipo de archivo no permitido',
          color_notification: 'danger',
        })
        return
      }
    }
  }

  private resetVars () {
    this.title = ''
    this.description = ''
    this.tags = ['ISO 27002', 'ISO 27001',]
    this.datarooms = []
    this.dataroomsName = []
    this.currentDatarooms = []
    this.fileToUpload = []
    this.selectedTags = []
  }

  private createTemplate () {
    if (this.title == '') {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error, El título no puede ser vacío',
        color_notification: 'danger',
      })
      return
    }

    this.loading = true

    const templateData: TemplateCrudRecomendation = {
      id: this.templateSelected.id,
      title: this.title,
      description: this.description,
      tags: this.selectedTags,
      datarooms_data: this.fileToUpload,
      datarooms_delete_ids: this.deleteRoom,
    }

    if (this.$route.name === 'templateEdit') {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Porfavor espere, Editando plantilla',
      })
      this.$store
        .dispatch('RecomendationModule/editTemplates', templateData)
        .then(() => {
          this.loading = false
          this.resetVars()
          this.$store.dispatch('NotificactionsModule/OpenNotification', {
            message: 'Éxito, Plantilla editada',
          })
          this.$router.push('/app/templates/')
        })
        .catch(() => {
          this.loading = false
          this.$router.push('/app/templates/')
        })
    } else {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Por favor espere, Creando plantilla',
      })
      this.$store
        .dispatch('RecomendationModule/createTemplates', templateData)
        .then(() => {
          this.loading = false
          this.resetVars()
          this.$store.dispatch('NotificactionsModule/OpenNotification', {
            message: 'Éxito, Plantilla creada',
          })
          this.$router.push('/app/templates/')
        })
        .catch(() => {
          this.loading = false
          this.$router.push('/app/templates/')
        })
    }
  }
}
